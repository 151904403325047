app.search = {
    name: 'search',
    description: 'your script description',
    init() {
        const searchButton = document.querySelector('.jsSearchBtn');
        const searchForm = document.querySelector('.search-form');
        const searchFormInput = searchForm.querySelector('.search-form__input');
        const searchReset = searchForm.querySelector('button[type=reset]');

        if (!searchButton || !searchForm) return;

        searchButton.addEventListener('click', () => {
            if (searchButton.getAttribute('aria-expanded') === 'false') {
                searchButton.setAttribute('aria-expanded', true);
                searchForm.classList.add('active');
            } else {
                searchButton.setAttribute('aria-expanded', false);
                searchForm.classList.remove('active');
            }
        });

        document.addEventListener('mouseup', (e) => {
            if (searchForm != e.target && searchFormInput != e.target) {
                searchForm.classList.remove('active');
            }
        });

        searchFormInput.addEventListener('input', () => {
            if (searchFormInput.value !== '') {
                document.querySelector('.search-form__btn.active').classList.remove('active');
                searchReset.classList.add('active');
            }
        });

        if (!searchReset) return;

        searchReset.addEventListener('click', () => {
            searchFormInput.value = '';
            searchForm.classList.remove('active');
        });
    },
};
