app.productGallery = {
    name: 'productGallery',
    description: 'your script description',
    init() {
        const productGalleryThumbs = new Swiper(".product-gallery-thumbs", {
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesProgress: true,
        });

        new Swiper(".product-gallery-slider", {
            effect: 'fade',
            slidesPerView: 1,
            spaceBetween: 15,
            thumbs: {
                swiper: productGalleryThumbs,
            },
        });
    },
};
