window.addEventListener("DOMContentLoaded", () => {
    app.burger.init();
    app.search.init();
    app.tabs.init();
    app.productGallery.init();
    app.asideFilters.init();

    const catalogCards = document.querySelectorAll('.catalog-card');

    catalogCards.forEach((card) => {
        const slider = card.querySelector('.catalog-card__slider-wrap');

        if (!slider) return;

        let sliderId = 'slider--' + Math.floor(Math.random() * 10000000000000 + Date.now());
        slider.setAttribute('id', sliderId);

        new Swiper(`#${sliderId} .catalog-card__slider`, {
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: `#${sliderId} .swiper-button-next`,
                prevEl: `#${sliderId} .swiper-button-prev`,
            },
        });
    });


    const aboutSliders = document.querySelectorAll('.about__slider');
    aboutSliders.forEach((_, index) => {
        new Swiper(`.about__slider--${index + 1}`, {
            loop: true,
            autoplay: true,
            effect: 'fade',
            slidesPerView: 1,
            spaceBetween: 30,
        });
    });

    Fancybox.bind('[data-fancybox]', {
        autoFocus: false,
        dragToClose: false,
        closeButton: false,
        showClass: 'fancybox-fadeIn',
        Toolbar: {
            display: {
                left: [
                ],
                middle: [],
                right: [
                    'iterateZoom',
                    'close',
                ],
            },
        },
        Thumbs: {
            showOnStart: false,
        },
    });
});