app.asideFilters = {
  name: 'yourScriptName',
  description: 'your script description',
  init() {
    const body = document.querySelector('body');
    const filterBtn = document.querySelector('.filter-btn');
    const asideFilters = document.querySelector('.aside-filters');

    if (!filterBtn || !asideFilters) return;

    filterBtn.addEventListener('click', () => {
      body.classList.add('_lock');
      asideFilters.classList.add('active');
    });

    const asideFiltersClose = asideFilters.querySelector('.aside-filters__close');

    if (!asideFiltersClose) return;

    asideFiltersClose.addEventListener('click', () => {
      body.classList.remove('_lock');
      asideFilters.classList.remove('active');
    });
  },
};
